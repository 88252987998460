import React, { useEffect, useState } from 'react';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../index.js'; // Ensure your Firebase initialization is correctly imported\

function LoginComponent({ userAuthenticationService }) {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, user => {
      setIsSignedIn(!!user);
      if (user) {
        userAuthenticationService.setUser(user);
        navigate('/'); // Redirect to home page or desired route on successful sign-in
      }
    });

    return () => unregisterAuthObserver(); // Unregister Firebase observers when the component unmounts
  }, [navigate, userAuthenticationService]);

  useEffect(() => {
    // Check if there's a redirect result when the component mounts
    const checkRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          setIsSignedIn(!!result.user);
          userAuthenticationService.setUser(result.user);
          navigate('/'); // Redirect to the home page or another desired route on successful sign-in
        }
      } catch (error) {
        setError(error.message); // Handle any errors
      }
    };

    checkRedirectResult();
  }, [navigate, userAuthenticationService]);

  const handleEmailSignIn = async () => {
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithRedirect(auth, provider, browserPopupRedirectResolver);
      // await signInWithPopup(auth, provider);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    setError(null);
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Check your inbox.');
    } catch (error) {
      setError(error.message); // Handle any errors during the password reset process
    }
  };

  if (isSignedIn) {
    navigate('/');
    return null; // If signed in, no need to show login form
  }

  return (
    <div className="flex h-screen flex-col bg-black">
      <div className="flex flex-grow items-center justify-center">
        <div className="flex flex-col items-center justify-center space-y-8">
          <div className="bg-secondary-dark mx-auto space-y-4 rounded-lg py-8 px-8 drop-shadow-md">
            <div className="space-y-2 text-center">
              <h2 className="text-primary-active text-2xl font-semibold">Login</h2>
              {error && <p className="text-red-500">{error}</p>}
            </div>
            <div className="space-y-4">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="focus:ring-primary-active w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="focus:ring-primary-active w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2"
              />
            </div>
            <div className="text-right">
              <button
                onClick={handleForgotPassword}
                className="text-primary-active text-sm hover:underline"
              >
                Forgot Password?
              </button>
            </div>
            <div className="flex flex-col space-y-4">
              <button
                onClick={handleEmailSignIn}
                className="bg-primary-active hover:bg-primary-dark w-full rounded-lg py-2 text-white transition"
              >
                Sign in with Email
              </button>
              {/* <button
                onClick={handleGoogleSignIn}
                className="w-full rounded-lg border border-gray-300 bg-white py-2 text-black transition hover:bg-gray-100"
              >
                Sign in with Google
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-black py-4 text-center text-sm">
        <div className="text-blue-300">
          <a
            href="https://www.nuclivision.com/privacy-policy"
            target="_blank"
            className="mx-4 inline-block hover:underline"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.nuclivision.com/terms-conditions"
            target="_blank"
            className="mx-4 inline-block hover:underline"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </div>
      </footer>
    </div>
  );
}

export default LoginComponent;
